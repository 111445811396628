import { Module, VuexModule, Mutation } from 'vuex-module-decorators'
import type { InputNotice } from '~~/types/inputNotice'
import type { UserId } from '~~/types/userId'
export const INPUT_NOTICE_MODULE_NAME = 'InputNoticeModule'

@Module({
  name: INPUT_NOTICE_MODULE_NAME,
  namespaced: true,
  stateFactory: true,
})
export default class InputNoticeModule extends VuexModule {

  /**
   * お知らせ一覧
   */
  private noticeList: InputNotice[] = []

  /**
   * お知らせのIDを基にお知らせを取得する処理
   * @param id お知らせのID
   * @param userId ユーザーID
   * @param orgId 組織ID
   */
  get getNotice() {
    return (userId: UserId, orgId: string, id: string) => this.noticeList.find((notice) => notice.id === id && notice.userId === userId && notice.orgId === orgId)
  }

  @Mutation
  public deleteNotice(noticeInput: InputNotice) {
    this.noticeList = this.noticeList.filter((notice) => !(notice.id === noticeInput.id && notice.userId === noticeInput.userId && notice.orgId === noticeInput.orgId))
  }

  @Mutation
  public addNewNotice(notice: InputNotice) {
    this.noticeList.push(notice)
  }

  @Mutation
  public updateNotice(updateNotice: InputNotice) {
    const index = this.noticeList.findIndex((notice) => notice.id === updateNotice.id && notice.userId === updateNotice.userId && notice.orgId === updateNotice.orgId)
    this.noticeList.splice(index, 1, updateNotice)
  }
}
