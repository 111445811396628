import { Store } from 'vuex'
import { initializeStores } from '~/store/accessor'
import NoticeModule, { NOTICE_MODULE_NAME } from './modules/notice-module'
import StudentModule, { STUDENT_MODULE_NAME } from './modules/student-module'
import AppStatusModule, { APP_STATUS_MODULE_NAME } from './modules/app-status-module'
import ChannelModule, { CHANNEL_MODULE_NAME } from './modules/channel-module'
import UnreadMessageCountModule, { UNREAD_MESSAGE_COUNT_MODULE_NAME } from './modules/unread-message-count-module'
import UnreadCountModule, { UNREAD_COUNT_MODULE_NAME } from './modules/unread-count-module'
import ReservedEventModule, { RESERVED_EVENT_MODULE_NAME } from './modules/reserved-event-module'
import AppStatusGuardModule, { APP_STATUS_GUARD_MODULE_NAME } from './modules/app-status-guard-module'
import GuardCalendarModule, { GUARD_CALENDAR_MODULE_NAME } from './modules/guard-calendar-module'
import EventManageModule, { EVENT_MANAGE_MODULE_NAME } from './modules/event-manage-module'
import InputNoticeModule, { INPUT_NOTICE_MODULE_NAME } from './modules/input-notice-module'
import InquiryModule, { INQUIRY_MODULE_NAME } from './modules/inquiry-module'
import VuexPersist from 'vuex-persist'

const vuexSessionStorage = new VuexPersist({
  key: 'vuex', // The key to store the state on in the storage provider.
  storage: window.sessionStorage, // or window.sessionStorage or localForage
  modules: [
    NOTICE_MODULE_NAME,
    STUDENT_MODULE_NAME,
    APP_STATUS_MODULE_NAME,
    UNREAD_MESSAGE_COUNT_MODULE_NAME,
    UNREAD_COUNT_MODULE_NAME,
    RESERVED_EVENT_MODULE_NAME,
    CHANNEL_MODULE_NAME,
    APP_STATUS_GUARD_MODULE_NAME,
    GUARD_CALENDAR_MODULE_NAME,
    EVENT_MANAGE_MODULE_NAME,
    INQUIRY_MODULE_NAME,
  ],
})

const vuexLocalStorage = new VuexPersist({
  key: 'vuex', // The key to store the state on in the storage provider.
  storage: window.localStorage, // or window.sessionStorage or localForage
  modules: [
    INPUT_NOTICE_MODULE_NAME,
  ],
})

const sessionStore = new Store({
  modules: {
    NoticeModule,
    StudentModule,
    AppStatusModule,
    UnreadMessageCountModule,
    UnreadCountModule,
    ReservedEventModule,
    ChannelModule,
    AppStatusGuardModule,
    GuardCalendarModule,
    EventManageModule,
    InquiryModule,
  },
  plugins: [
    vuexSessionStorage.plugin,
  ],
})

const localStore = new Store({
  modules: {
    InputNoticeModule,
  },
  plugins: [
    vuexLocalStorage.plugin,
  ],
})

const initializer = (sessionStore: Store<any>, localStore: Store<any>) => initializeStores(sessionStore, localStore)
initializer(sessionStore, localStore)
export const plugins = [initializer]
export * from '~/store/accessor'
