import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'
import GetUnreadMessagesService from '~/services/GetUnreadMessagesService'
import type { UnreadRes } from '~~/types/unreadRes'

export const UNREAD_MESSAGE_COUNT_MODULE_NAME = 'UnreadMessageCountModule'

import { inject } from 'vue'
import { updateIsBackgroundLoadingKey } from '~/const/updateIsBackgroundLoadingKey'
import * as Sentry from '@sentry/vue'

@Module({
  name: UNREAD_MESSAGE_COUNT_MODULE_NAME,
  namespaced: true,
  stateFactory: true,
})
export default class UnreadMessageCountModule extends VuexModule {

  /**
   *  未読連絡件数
   */
  private unreadMessageCount =  0

  /**
   * 未読連絡件数を返却する処理
   */
  get getUnreadMessageCount() {
    return this.unreadMessageCount
  }

  @Mutation
  public resetUnreadMessageCount() {
    this.unreadMessageCount =  0
  }

  @Mutation
  public setUnreadMessageCount(unreadCount: number){
    this.unreadMessageCount = unreadCount
  }

  /**
   * APIから未読連絡件数を取得する処理
   */
  @Action
  public loadUnreadMessageCount(){
    const backgroundLoading = inject(updateIsBackgroundLoadingKey)!
    backgroundLoading?.('loadUnreadMessageCount', true)
    GetUnreadMessagesService.get().then((response: UnreadRes) => {
      this.setUnreadMessageCount(response.data.unreadCount)
    })
    .catch((error) => {
      // Sentryにエラーを通知
      Sentry.captureException(error)
    }).finally(() => {
      backgroundLoading?.('loadUnreadMessageCount', false)
    })
  }
}
