import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'
import type { UnreadCountRes } from '~~/types/unreadCountRes'
import http from '~/http-common'
import { ApiPath } from '~/enum/apiPath'
import { inject } from 'vue'
import { updateIsBackgroundLoadingKey } from '~/const/updateIsBackgroundLoadingKey'
import * as Sentry from '@sentry/vue'

export const UNREAD_COUNT_MODULE_NAME = 'UnreadCountModule'

@Module({
  name: UNREAD_COUNT_MODULE_NAME,
  namespaced: true,
  stateFactory: true,
})

/**
 * 保護者の各種未読件数を扱うStoreModule
 */
export default class UnreadCountModule extends VuexModule {

  /**
   *  未読件数(お知らせ)
   */
  private noticeCount = 0
  /**
   *  未読件数(イベント)
   */
  private eventCount = 0
  /**
   *  未読件数(チャンネル)
   */
  private channelCount = 0

  /**
   * 未読件数(お知らせ)を返却する処理
   */
  get getNoticeCount () {
    return this.noticeCount
  }
  /**
   * 未読件数(イベント)を返却する処理
   */
  get getEventCount () {
    return this.eventCount
  }
  /**
   * 未読件数(チャンネル)を返却する処理
   */
  get getChannelCount () {
    return this.channelCount
  }

  @Mutation
  public resetNoticeCount () {
    this.noticeCount =  0
  }
  @Mutation
  public resetEventCount () {
    this.eventCount =  0
  }
  @Mutation
  public resetChannelCount () {
    this.channelCount =  0
  }

  @Mutation
  public setNoticeCount (noticeCount: number) {
    this.noticeCount = noticeCount
  }
  @Mutation
  public setEventCount (eventCount: number) {
    this.eventCount = eventCount
  }
  @Mutation
  public setChannelCount (channelCount: number) {
    this.channelCount = channelCount
  }

  /**
   * APIから未読件数を取得する処理
   */
  @Action
  public loadUnreadCount (stuId: string) {
    const backgroundLoading = inject(updateIsBackgroundLoadingKey)!
    backgroundLoading?.('loadUnreadCount', true)
    this.getUnreadCount(stuId).then((response: UnreadCountRes) => {
      this.setNoticeCount(response.data.noticeCount)
      this.setEventCount(response.data.eventCount)
      this.setChannelCount(response.data.channelCount)
    })
    .catch((error) => {
      // Sentryにエラーを送信
      Sentry.captureException(error)
    })
    .finally(() => {
      backgroundLoading?.('loadUnreadCount', true)
    })
  }

  @Action
  public async getUnreadCount (stuId: string): Promise<UnreadCountRes> {
    const path = ApiPath.UNREAD_COUNT_MODULE_GET_UNREAD_COUNT + '?stuId=' + stuId
    const headers = {
      mode: 'cors',
      credentials: 'include',
      csrfToken: sessionStorage.getItem('token')!,
    }
    return http.get(path, { headers: headers })
  }
}
