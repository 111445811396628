import http from '~/http-common'
import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'
import type { manamiruChannel } from '~~/types/manamiruChannel'
import type { GetChannelListRes } from '~~/types/getChannelListRes'
import type { GetChannelDetailRes } from '~~/types/getChannelDetailRes'
import { API_URL } from '~/const/env'
import { ApiPath } from '~/enum/apiPath'
import { inject } from 'vue'
import { updateIsBackgroundLoadingKey } from '~/const/updateIsBackgroundLoadingKey'
import * as Sentry from '@sentry/vue'

export const CHANNEL_MODULE_NAME = 'ChannelModule'

@Module({
  name: CHANNEL_MODULE_NAME,
  namespaced: true,
  stateFactory: true,
})
export default class ChannelModule extends VuexModule {
  private channelDatas: manamiruChannel[] = []
  private channelCount = 0

  get getChannelDatas() {
    return this.channelDatas
  }

  get getChannelCount() {
    return this.channelCount
  }

  @Mutation
  public resetChannelList() {
    this.channelDatas = []
    this.channelCount = 0
  }

  @Mutation
  public setChannelCount(channelCount: number) {
    this.channelCount = channelCount
  }

  @Mutation
  public setChannelRead(noticeIdList: string[]) {
    noticeIdList.forEach(noticeId => {
      const channel = this.channelDatas.filter(channel => channel.id == parseInt(noticeId))[0]
      channel.readStsDiv = '1'
    })
  }

  /**
   * guard/F30419/initへのGETを行う
   * @params limit
   * @paramsurl
   * @returns HttpResponseData
   */
  @Action
  public async getChannelList(): Promise<GetChannelListRes> {
    const headers = {
      mode: 'cors',
      credentials: 'include',
      csrfToken: sessionStorage.getItem('token')!,
      'Content-type': 'multipart/form-data',
    }
    const url = encodeURIComponent(API_URL + ApiPath.GUARD_F30419).toString()
    const path = ApiPath.CHANNEL_MODULE_GET_CHANNEL_LIST
    return http.get(path + '?url=' + url, { headers: headers })
  }

  /**
   * guard/F30420/initへのGETを行う
   * @params noticeId
   * @returns HttpResponseData
   */
  @Action
  public async getChannelDetail (noticeId: number): Promise<GetChannelDetailRes> {
    const headers = {
      mode: 'cors',
      credentials: 'include',
      csrfToken: sessionStorage.getItem('token')!,
    }
    const query = '?noticeId=' + noticeId.toString()
    const path = ApiPath.CHANNEL_MODULE_GET_CHANNEL_DETAIL
    return http.get(path + query, { headers: headers})
  }

  @Action
  public loadChannelList() {
    const backgroundLoading = inject(updateIsBackgroundLoadingKey)!
    backgroundLoading?.('loadChannelList', true)
    this.getChannelList().then(result =>
      result.data.showList.forEach(element => {
        this.channelDatas.push({
          id: element.id,
          noticeContent: element.noticeContent,
          noticeImgPath: element.noticeImgPath == null ? '' : element.noticeImgPath,
          noticeLevDiv: element.noticeLevDiv,
          noticeTitle: element.noticeTitle,
          orgId: element.orgId,
          readStsDiv: element.readStsDiv,
          attachFilePath: '',
          codValue: '',
          openedFlg: '',
          originalFilenm: '',
          startTime: element.startTime,
          endTime: element.endTime,
          updTime: '',
        })
        this.setChannelCount(result.data.dataCount)
      })
    ).catch((error) => {
      // Sentryにエラーを送信
      Sentry.captureException(error)
    }).finally(() => {
      backgroundLoading?.('loadChannelList', false)
    })
  }

  @Action
  public async loadChannelDetail(noticeId: number) {
    const channel = this.channelDatas.filter(channel => channel.id == noticeId)[0]
    await this.getChannelDetail(noticeId).then(result => {
        channel.attachFilePath = result.data.f30420Dto.attachFilePath
        channel.codValue = result.data.f30420Dto.codValue
        channel.openedFlg = result.data.f30420Dto.openedFlg
        channel.originalFilenm = result.data.f30420Dto.originalFilenm
        channel.updTime = result.data.f30420Dto.updTime
      }
    )
  }
}
