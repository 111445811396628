import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import type { Student } from '~~/types/student'
import type { UserId } from '~~/types/userId'
import RegisterStudentDatasService from '~/services/StudentDatasService'
import type { F21017Params } from '~~/types/F21017Params'
import type { F21017Dto } from '~~/types/F21017Dto'
import type { StudentRes } from '~~/types/studentRes'
import { Schy } from '~/enum/schy'
import { Gender } from '~/enum/gender'
import { FstLoginFlg } from '~/enum/fstLoginFlg'
import { Dayweek } from '~/enum/dayweek'
import apiSchy from '~/const/apiSchy'
import apiGender from '~/const/apiGender'
import apiFstLoginFlg from '~/const/apiFstLoginFlg'
import apiDayweek from '~/const/apiDayweek'
import GetStudentDatasService from '~/services/GetStudentDatasService'
import GetStudentAbsStsService from '~/services/GetStudentAbsStsService'
import { getAge } from '~/libs/ageFormatter'
import { convertDivForFront } from '~/libs/convertDivForFront'
import { getDefaultAvatarPath } from '~/libs/getDefaultAvatarPath'
import { convertForApi } from '~/libs/convertDivForApi'
import { DateUtil } from '~/utils/date-util'
import { FreeTrialStatus } from '~/enum/freeTrialStatus'
import { memberAdmissionStatusCheck } from '~/libs/memberAdmissionStatusCheck'

export const STUDENT_MODULE_NAME = 'StudentModule'

@Module({
  name: STUDENT_MODULE_NAME,
  namespaced: true,
  stateFactory: true,
})
export default class StudentModule extends VuexModule {

  /**
   *  生徒情報一覧
   */
  private studentList: Student[] = []

  /**
   * 生徒情報一覧を取得する処理
   */
  get getStudentList() {
    return this.studentList
  }

  /**
   * ユーザIDを元に生徒情報を取得する処理
   */
  get getStudentByUsrId() {
    return (usrId: UserId) => this.studentList.find((student) => student.usrId === usrId)
  }

  @Mutation
  public resetStudentList() {
    this.studentList = []
  }

  @Mutation
  public setStudents(students: Student[]) {
    this.studentList = students
  }

  @Mutation
  public addNewStudent(student: Student){
    this.studentList.push(student)
  }

  @Mutation
  public addNewStudentList(studentList: Student[]) {
    studentList.forEach(student => {
      this.studentList.push(student)
    })
  }

  @Mutation
  public setMailad({ stuId, mailad }: { stuId: string, mailad: string }) {
    const targetStudent = this.studentList.find((student) => student.usrId === stuId)!
    targetStudent.mailad = mailad
  }

  // Studentデータを取得する関数
  @Action
  public async getStudentDatas(params: F21017Params): Promise<Student[]> {
    const studentDatas: Student[] = []
    // APIから取得したデータをStudent型に格納
    const result: StudentRes = await GetStudentDatasService.post(JSON.stringify(params))
    if (result.data.code == 0) {
      const stuList: F21017Dto[] = result.data.stuList.filter(x => x.guardId !== null && x.flnmNmGuard !== null)
      sessionStorage.setItem('studentCount', result.data.count)
      stuList.forEach((element: F21017Dto) => {
        let fstLoginFlg = element.fstLoginFlg
        if (!fstLoginFlg) {
          fstLoginFlg = '2'
        }
        if (element.schyDiv == undefined) {
          return
        }
        const studentData: Student = {
          usrId          : element.stuId,
          name           : element.stuName,
          birthd         : element.birthd,
          birthdayText   : element.birthDayTxt,
          avatar         : getDefaultAvatarPath(element.photPath),
          schy           : Schy[convertDivForFront(element.schyDiv, apiSchy) as keyof typeof Schy],
          strSchy        : element.schyDiv,
          entryDt        : element.entryDt,
          age            : getAge(new DateUtil(element.birthd).date),
          orgId          : element.orgId,
          sch            : element.sch,
          guardId        : element.guardId,
          flnmNm         : element.flnmNm,
          flnmLnm        : element.flnmLnm,
          flnmKnNm       : element.flnmKnNm,
          flnmKnLnm      : element.flnmKnLnm,
          gender         : Gender[convertDivForFront(element.gendrDiv, apiGender) as keyof typeof Gender],
          memoCont       : element.memoCont === null ? '' : element.memoCont,
          admissionDate  : element.admissionDate,
          fstLoginFlg    : FstLoginFlg[convertDivForFront(fstLoginFlg, apiFstLoginFlg) as keyof typeof FstLoginFlg],
          mailad         : '',
          nameGuard      : element.nameGuard,
          flnmNmGuard    : element.flnmNmGuard,
          flnmLnmGuard   : element.flnmLnmGuard,
          flnmKnNmGuard  : element.flnmKnNmGuard,
          flnmKnLnmGuard : element.flnmKnLnmGuard,
          telnum         : element.telnum,
          urgTelnum      : element.urgTelnum,
          dayweek        : setDayweekDiv(element.dayweekDiv),
          entryFlg       : Number(element.entryFlg),
          tgtYmd         : element.tgtYmd,
          absSts         : element.absSts,
          id             : Number(element.id),
          updDatime      : element.updDatime,
          orgNm          : '',
          qrCod          : element.qrCod,
          memberCode     : element.memberCd,
          courseId       : element.courseId,
          courseNm       : element.courseDisplayName,
          courseAuthorizationCode: element.courseAuthorizationCode,
          englishAuthorizationFlg: element.englishAuthorizationFlg,
          freeTrialStatus: FreeTrialStatus[element.freeTrialStatus as keyof typeof FreeTrialStatus],
          admissionDiv   : element.admissionDiv,
          memberAdmissionStatus: memberAdmissionStatusCheck(FreeTrialStatus[element.freeTrialStatus as keyof typeof FreeTrialStatus], element.admissionDiv)
        }

        // リストにデータを追加
        studentDatas.push(studentData)
      })
    } else {
      // TODO: エラー処理
    }
    return studentDatas
  }

  // 生徒の入退室ステータスを再取得
  @Action
  public async updateStudentAbsSts(studentDatas: Student[]): Promise<Student[]> {
    // APIから取得したデータをStudent型に格納
    const studentParams: F21017Params = {
      schoolYear: '',
      week: '',
      status: '',
    }
    const result: StudentRes = await GetStudentAbsStsService.post(JSON.stringify(studentParams))
    if (result.data.code == 0) {
      const stuList: F21017Dto[] = result.data.stuList
      // 入退室ステータスを更新
      studentDatas.forEach((elemStu: Student) => {
        for (const elemAbs of stuList) {
          // 同一生徒の情報を更新
          if (elemStu.id == Number(elemAbs.id)) {
            elemStu.absSts = elemAbs.absSts
            elemStu.entryDt = elemAbs.entryDt
            elemStu.entryFlg = Number(elemAbs.entryFlg)
            break
          }
        }
      })
      // entryFlg, entryDt, memberCode順にソート
      studentDatas.sort((a: Student, b: Student) => {
        if (a.entryDt == null && b.entryDt == null) {
          if (a.memberCode == null) {
            return 1
          } else if (b.memberCode == null) {
            return -1
          } else {
            return a.memberCode.localeCompare(b.memberCode)
          }
        } else if (a.entryDt == null) {
          return 1
        } else if (b.entryDt == null) {
          return -1
        } else {
          if (a.entryFlg !== b.entryFlg) {
            return a.entryFlg - b.entryFlg
          } else if (a.entryDt !== b.entryDt) {
            return new DateUtil(a.entryDt).date.valueOf() - new DateUtil(b.entryDt).date.valueOf()
          } else {
            if (a.memberCode == null) {
              return 1
            } else if (b.memberCode == null) {
              return -1
            } else {
              return a.memberCode.localeCompare(b.memberCode)
            }
          }
        }
      })
    } else {
      // TODO: エラー処理
    }
    return studentDatas
  }

  @Action
  public async updateStudentAction (studentData: Student): Promise<boolean> {
    const memo = studentData.memoCont
    const stuId = studentData.usrId
    const updDatime = studentData.updDatime
    // store更新
    const targetData = this.getStudentByUsrId(stuId)
    // 通塾日
    targetData!.dayweek = studentData.dayweek
    // メモ
    targetData!.memoCont = memo

    let dayweekStr = ""
    for(let i = 0; i < studentData.dayweek.length; i++) {
      // enumのvalueからkeyを取得
      let dayweekKey = ''
      Object.entries(Dayweek).forEach(([key, value]) => {
        if (value == studentData.dayweek[i]) {
          dayweekKey = key
          return
        }
      });
      dayweekStr += convertForApi(dayweekKey, apiDayweek)
      if(i != studentData.dayweek.length - 1) {
        dayweekStr += ','
      }
    }

    return await RegisterStudentDatasService.update(memo, dayweekStr, stuId, updDatime).then(result => {
      if(result.data.code != 0) {
        return false
      }
      // データ更新後、ストアのupdDatime更新
      targetData!.updDatime = result.data.updDatime
      return true
    })
  }

  @Mutation
  public setFreeTrialStatus({ stuId, updateDate }: { stuId: string, updateDate: string }) {
    const targetStudent = this.studentList.find((student) => student.usrId === stuId)!
    targetStudent.updDatime = updateDate
    targetStudent.freeTrialStatus = FreeTrialStatus.TRIALING
  }
}

// 曜日を配列に変換
const setDayweekDiv = (deyweekDiv: string) => {
  const dayweekArray: Dayweek[] = []
  if (deyweekDiv != null && deyweekDiv != '') {
    const deyweekDivArray: string[] = deyweekDiv.split(',')
    deyweekDivArray.forEach(str => {
      dayweekArray.push(Dayweek[convertDivForFront(str, apiDayweek) as keyof typeof Dayweek])
    })
  } else {
    Object.entries(Dayweek).forEach(([key, value]) => {
      if (key != 'all') {
        dayweekArray.push(value)
      }
    })
  }
  return dayweekArray
}
