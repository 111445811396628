import { Store } from 'vuex'
import { getModule } from 'vuex-module-decorators'
import NoticeModule from '~/store/modules/notice-module'
import StudentModule from '~/store/modules/student-module'
import AppStatusModule from '~/store/modules/app-status-module'
import UnreadMessageCountModule from '~/store/modules/unread-message-count-module'
import UnreadCountModule from '~/store/modules/unread-count-module'
import ReservedEventModule from '~/store/modules/reserved-event-module'
import ChannelModule from '~/store/modules/channel-module'
import AppStatusGuardModule from '~/store/modules/app-status-guard-module'
import GuardCalendarModule from '~/store/modules/guard-calendar-module'
import EventManageModule from '~/store/modules/event-manage-module'
import InputNoticeModule from '~/store/modules/input-notice-module'
import InquiryModule from '~/store/modules/inquiry-module'

let noticeStore: NoticeModule
let studentStore: StudentModule
let appStatusStore: AppStatusModule
let unreadMessageCountStore: UnreadMessageCountModule
let unreadCountStore: UnreadCountModule
let reservedEventStore: ReservedEventModule
let channelStore: ChannelModule
let appStatusGuardStore: AppStatusGuardModule
let guardCalendarStore: GuardCalendarModule
let eventManageStore: EventManageModule
let inputNoticeStore: InputNoticeModule
let inquiryStore: InquiryModule

/**
 * ストアを初期化する（型推論できるモジュールとして取得する）
 * @param store Vuex.Store
 */
function initializeStores(sessionStore: Store<any>, localStore: Store<any>): void {
  // sessionStorage
  noticeStore = getModule(NoticeModule, sessionStore)
  studentStore = getModule(StudentModule, sessionStore)
  appStatusStore = getModule(AppStatusModule, sessionStore)
  unreadMessageCountStore = getModule(UnreadMessageCountModule, sessionStore)
  unreadCountStore = getModule(UnreadCountModule, sessionStore)
  reservedEventStore = getModule(ReservedEventModule, sessionStore)
  channelStore = getModule(ChannelModule, sessionStore)
  appStatusGuardStore = getModule(AppStatusGuardModule, sessionStore)
  guardCalendarStore = getModule(GuardCalendarModule, sessionStore)
  eventManageStore = getModule(EventManageModule, sessionStore)
  inquiryStore = getModule(InquiryModule, sessionStore)

  // localStorage
  inputNoticeStore = getModule(InputNoticeModule, localStore)
}

export {
  initializeStores,
  noticeStore,
  studentStore,
  appStatusStore,
  unreadMessageCountStore,
  unreadCountStore,
  reservedEventStore,
  channelStore,
  appStatusGuardStore,
  guardCalendarStore,
  eventManageStore,
  inputNoticeStore,
  inquiryStore,
}
