import { Module, VuexModule, Mutation } from 'vuex-module-decorators'
import type { InquiryInfo } from '~~/types/inquiryInfo'

export const INQUIRY_MODULE_NAME = 'InquiryModule'

@Module({
  name: INQUIRY_MODULE_NAME,
  namespaced: true,
  stateFactory: true,
})
export default class InquiryModule extends VuexModule {
  /**
   * お問い合わせ情報
   */
  private inquiryInfo: InquiryInfo = {
    mailAddress: '',
    reEnterMailAddress: '',
    classroomCode: '',
    memberCode: '',
    inquiryContentDiv: '',
    inquiryContentMessage: '',
    guardId: '',
    registeredMailAddress: '',
    guardLastName: '',
    guardFirstName: '',
    stuLastName: '',
    stuFirstName: '',
    stuKanaLastName: '',
    stuKanaFirstName: '',
    birthDay: '',
    birthDayYear: '',
    birthDayMonth: '',
    birthDayDate: '',
    tel1: '',
    tel2: '',
    postCode: '',
    address1Code: '',
    address1: '',
    address2: '',
    address3: '',
    address4: '',
  }

  /**
   * イベント情報の取得
   */
  get getInquiryInfo() {
    return this.inquiryInfo
  }

  /**
   * イベント情報の設定
   */
  @Mutation
  public setInquiryDetail(value: InquiryInfo) {
    this.inquiryInfo = value
  }
}
