import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import type { AppStatusGuard } from '~~/types/appStatusGuard'
import type { Child } from '~~/types/child'
import type { guardChildrenDatas } from '~~/types/guardChildrenDatas'
import type { AppStatusGuardRes } from '~~/types/appStatusGuardRes'
import { convertDivForFront } from '~/libs/convertDivForFront'
import { Schy } from '~/enum/schy'
import apiSchy from '~/const/apiSchy'
import http from '~/http-common'
import { getDefaultAvatarPath } from '~/libs/getDefaultAvatarPath'
import { ApiPath } from '~/enum/apiPath'
import { FreeTrialStatus } from '~/enum/freeTrialStatus'
import { memberAdmissionStatusCheck } from '~/libs/memberAdmissionStatusCheck'
import { MemberAdmissionStatus } from '~/enum/memberAdmissionStatus'

export const APP_STATUS_GUARD_MODULE_NAME = 'AppStatusGuardModule'

@Module({
  name: APP_STATUS_GUARD_MODULE_NAME,
  namespaced: true,
  stateFactory: true,
})
export default class AppStatusGuardModule extends VuexModule {
  private appStatusGuard: AppStatusGuard = {
    children: [],
    selectedChild: {
      userId: '',
      flnmFnm: '',
      flnmLnm: '',
      schy: null,
      schyDiv: '',
      orgId: '',
      orgNm: '',
      avatar: getDefaultAvatarPath(''),
      qrcode: '',
      unReadCount: 0,
      disagreeFlg: '',
      freeTrialStatus: FreeTrialStatus.NOT_TRIED,
      memberAdmissionStatus: MemberAdmissionStatus.NOT_MEMBER,
    },
    isBrowser: false,

  }
  @Mutation
  public setSelectedChild(child: Child) {
    this.appStatusGuard.selectedChild = child
  }
  @Mutation
  public setIsBrowser(isBrowser: boolean) {
    this.appStatusGuard.isBrowser = isBrowser
  }

  @Mutation
  public resetAppStatusGuard() {
    this.appStatusGuard = {
      children: [],
      selectedChild: {
        userId: '',
        flnmFnm: '',
        flnmLnm: '',
        schy: null,
        schyDiv: '',
        orgId: '',
        orgNm: '',
        avatar: getDefaultAvatarPath(''),
        qrcode: '',
        unReadCount: 0,
        disagreeFlg: '',
        freeTrialStatus: FreeTrialStatus.NOT_TRIED,
        memberAdmissionStatus: MemberAdmissionStatus.NOT_MEMBER,
      },
      isBrowser: false,
    }
  }

  get getAvatar() {
    return this.appStatusGuard.selectedChild.avatar
  }

  get getAppStatusGuard() {
    return this.appStatusGuard
  }

  get getSelectedChild() {
    return this.appStatusGuard.selectedChild
  }

  get getMemberAdmissionStatus() {
    return this.appStatusGuard.selectedChild.memberAdmissionStatus
  }

  get getIsBrowser() {
    return this.appStatusGuard.isBrowser
  }

  /**
   * guard/F30420/initへのGETを行う
   * @params noticeId
   * @returns HttpResponseData
   */
  @Action
  public async getChildren (): Promise<AppStatusGuardRes> {
    const headers = {
      mode: 'cors',
      credentials: 'include',
      csrfToken: sessionStorage.getItem('token')!,
    }
    const path = ApiPath.APP_STATUS_GUARD_MODULE_LOAD_CHILDREN
    return http.get(path, { headers: headers })
  }

  @Action
  public async loadChildren(): Promise<AppStatusGuardRes> {
    const selectedChild = this.appStatusGuard.selectedChild
    // API実行
    const result: AppStatusGuardRes = await this.getChildren()
    if (result.data.code == 0) {
      // Storeの初期化
      this.resetAppStatusGuard()
      // Storeの再設定
      const stuList = result.data.stuList
      stuList.forEach((element: guardChildrenDatas) => {
        const child: Child = {
          userId: element.stuId,
          flnmFnm: element.flnmNm,
          flnmLnm: element.flnmLnm,
          schy: Schy[convertDivForFront(element.schyDiv, apiSchy) as keyof typeof Schy],
          schyDiv: element.schyDiv,
          orgId: element.orgId,
          orgNm: element.orgNm,
          avatar: getDefaultAvatarPath(element.photPath),
          qrcode: element.qrCod,
          unReadCount: element.unReadCount,
          disagreeFlg: element.disagreeFlg,
          freeTrialStatus: FreeTrialStatus[element.freeTrialStatus as keyof typeof FreeTrialStatus],
          memberAdmissionStatus: memberAdmissionStatusCheck(FreeTrialStatus[element.freeTrialStatus as keyof typeof FreeTrialStatus], element.admissionDiv),
        }
        this.appStatusGuard.children.push(child)
      })
      this.appStatusGuard.selectedChild = selectedChild
      this.appStatusGuard.isBrowser = result.data.isBrowser
    }
    return result
  }
}
