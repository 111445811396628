import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import type { Organization } from '~~/types/organization'
import type { AppStatus } from '~~/types/appStatus'
import LoginApiService from '~/services/LoginApiService'

export const APP_STATUS_MODULE_NAME = 'AppStatusModule'

import { inject } from 'vue'
import { updateIsBackgroundLoadingKey } from '~/const/updateIsBackgroundLoadingKey'
import * as Sentry from '@sentry/vue'

@Module({
  name: APP_STATUS_MODULE_NAME,
  namespaced: true,
  stateFactory: true,
})
export default class AppStatusModule extends VuexModule {

  /**
   *  アプリ情報一覧
   */
  private appStatus: AppStatus = {
    orgList: [],
    selectedOrgId: '',
    usrId: '',
  }

  /**
   * アプリ情報一覧を取得する処理
   */
  get getAppStatus() {
    return this.appStatus
  }

  @Mutation
  public resetAppStatus() {
    this.appStatus = {
      orgList: [],
      selectedOrgId: '',
      usrId: '',
    }
  }

  @Mutation
  public setOrg(org: Organization) {
    this.appStatus.orgList.push(org)
  }

  @Mutation
  public setSelectedOrgId(orgId: string){
    this.appStatus.selectedOrgId = orgId
  }

  @Mutation
  public setUsrId(usrId: string){
    this.appStatus.usrId = usrId
  }

  @Action
  public async loadInitAppStatus(){
    const backgroundLoading = inject(updateIsBackgroundLoadingKey)!
    backgroundLoading?.('loadInitAppStatus', true)
    await LoginApiService.getClassrooms().then((resData) => {
      if(resData.code == 0) {
        this.appStatus.orgList = []
        resData.f40013DtoList.forEach(element => {
          if (this.appStatus.orgList.length == 0) {
            this.setSelectedOrgId(element.orgId)
          }
          const org: Organization = {
            id: element.orgId,
            name: element.orgNm,
            isAvailableEnglish: element.isAvailableEnglish,
            disagreeFlg: element.disagreeFlg,
          }
          this.setOrg(org)
        })
        if (resData.orgNow != '') {
          this.setSelectedOrgId(resData.orgNow)
        }
      }
    })
    .catch((error) => {
      // Sentryにエラーを送信
      Sentry.captureException(error)
    })
    .finally(() => {
      backgroundLoading?.('loadInitAppStatus', false)
    })
  }
}
