import { Module, VuexModule, Mutation } from 'vuex-module-decorators'
import type { EventInfo } from '~~/types/eventInfo'
import type { EventDetail } from '~~/types/eventDetail'
import type { EventTemplateInfo } from '~~/types/eventTemplateInfo'
import { PublishDiv } from '~/enum/publishDiv'

export const EVENT_MANAGE_MODULE_NAME = 'EventManageModule'

@Module({
  name: EVENT_MANAGE_MODULE_NAME,
  namespaced: true,
  stateFactory: true,
})
export default class EventManageModule extends VuexModule {
  /**
   * イベント概要情報
   */
  private eventInfo: EventInfo = {
    eventId: 0,
    eventTitle: '',
    applyStartDate: '',
    applyEndDate: '',
    pubStartDate: '',
    pubEndDate: '',
    publishDiv: PublishDiv.draft,
    responseRate: 0,
    updateStrForCheck: '',
    createDate: ''
  }

  /**
   * イベント詳細情報
   */
  private eventDetail: EventDetail = {
    eventId: 0,
    eventTitle: '',
    eventContent: '',
    attachFilePath: [],
    originalFileNm: [],
    modifiableDays: 1,
    targetUsers: [],
    noticeFlag: '0',
    remindFlag: '0',
  }

  /**
   * イベントテンプレート情報
   */
  private eventTemplateInfos: EventTemplateInfo[] = []

  /**
   * イベント情報の取得
   */
  get getEventInfo() {
    return this.eventInfo
  }

  /**
   * イベント情報の設定
   */
  @Mutation
  public setEventInfo(value: EventInfo) {
    this.eventInfo = value
  }

  /**
   * イベント詳細情報の取得
   */
  get getEventDetail() {
    return this.eventDetail
  }

  /**
   * イベント詳細情報の設定
   */
  @Mutation
  public setEventDetail(value: EventDetail) {
    this.eventDetail = value
  }

  /**
   * イベントテンプレート情報の取得
   */
  get getEventTemplateInfos() {
    return this.eventTemplateInfos
  }

  /**
   * イベントテンプレート情報の設定
   */
  @Mutation
  public setEventTemplateInfos(value: EventTemplateInfo[]) {
    this.eventTemplateInfos = value
  }

  @Mutation
  public addEventTemplateInfos(value: EventTemplateInfo) {
    this.eventTemplateInfos.push(value)
  }
}
