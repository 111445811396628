import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import type { CalendarData } from '~~/types/calendarData'
import { dateFormatter } from '~/libs/dateFormatter'
import GetCalendarDatasService from '~/services/GetCalendarDatasService'
import type { CalendarDatasRes } from '~~/types/calendarDatasRes'
import type { EntryExitHst } from '~~/types/entryExitHst'
import type { StuComplimentMstEntity } from '~~/types/stuComplimentMstEntity'
import { makeFilePath } from '~/libs/makeFilePath'
import type { InterviewEvent } from '~~/types/interviewEvent'
import { DateUtil } from '~/utils/date-util'

export const GUARD_CALENDAR_MODULE_NAME = 'GuardCalendarModule'

@Module({
  name: GUARD_CALENDAR_MODULE_NAME,
  namespaced: true,
  stateFactory: true,
})
export default class GuardCalendarModule extends VuexModule {

  /**
   *  保護者カレンダーデータリスト
   */
  private calendarDataList: CalendarData[] = []

  /**
   * 保護者カレンダーデータリストを取得する処理
   */
  get getCalendarDataList() {
    return this.calendarDataList
  }

  /**
   * 年月を元に保護者カレンダーデータを取得する処理
   */
  get getCalendarDataByTargetMonth() {
    return (targetMonth: string) => this.calendarDataList.filter(
      x => dateFormatter(x.date, 'YYYYMM', '') === targetMonth)
  }

  @Mutation
  public resetCalendarDataList() {
    this.calendarDataList = []
  }

  @Mutation
  public setCalendarDataList(calendarDataList: CalendarData[]) {
    this.calendarDataList = calendarDataList
  }

  @Mutation
  public addNewCalendarData(calendarData: CalendarData){
    this.calendarDataList.push(calendarData)
  }

  @Mutation
  public addNewCalendarDataList(calendarDataList: CalendarData[]) {
    calendarDataList.forEach(calendarData => {
      this.calendarDataList.push(calendarData)
    })
  }

  // 1ヶ月分の保護者カレンダーデータを取得する関数
  @Action
  public async getCalendarDatas(targetDate: Date): Promise<CalendarData[]> {
    const calendarDatas: CalendarData[] = []
    // APIからデータを取得
    const result: CalendarDatasRes = await GetCalendarDatasService.getCalendarDatas(targetDate)
    if (result.data.code == 0) {
      // 入退室履歴
      const entryExitHstList: EntryExitHst[] = result.data.entryExitHstList
      // 入室履歴を昇順で取り出し
      const entryHst: EntryExitHst[] = entryExitHstList.filter(x => x.entryFlg === '0').sort((a, b) => new DateUtil(a.entryDt).date.getTime() - new DateUtil(b.entryDt).date.getTime())
      // 退室履歴を昇順で取り出し
      const exitHst: EntryExitHst[] = entryExitHstList.filter(x => x.entryFlg === '1').sort((a, b) => new DateUtil(a.entryDt).date.getTime() - new DateUtil(b.entryDt).date.getTime())
      let idx = 0
      let prevTargetDate = ''

      entryHst.forEach(element => {
        const targetDate = dateFormatter(element.entryDt, 'YYYYMMDD', '')
        // 入室日と同じ日付の退室日を取得
        const targetExitHst: EntryExitHst[] = exitHst.filter(x => dateFormatter(x.entryDt, 'YYYYMMDD', '') === targetDate)
        if (targetDate == prevTargetDate) {
          idx++
        } else {
          idx = 0
          prevTargetDate = targetDate
        }
        const calendarData: CalendarData = {
          date: new DateUtil(element.entryDt).date,
          title: '入室 ' + dateFormatter(element.entryDt, 'hhmm', '')
                + ' 退室 ' + (targetExitHst.length > idx ? dateFormatter(targetExitHst[idx].entryDt, 'hhmm', '') : '--:--'),
          reservedTime: '',
          customField: {
            dataType: 'attendance',
            stampPath: '',
          },
        }
        calendarDatas.push(calendarData)
      })
      // 褒めスタンプ・メッセージ
      const stuComplimentMstEntityList: StuComplimentMstEntity[] = result.data.stuComplimentMstEntityList
      stuComplimentMstEntityList.forEach(element => {
        const calendarData: CalendarData = {
          date: new DateUtil(element.complimentDt).date,
          title: element.complimentCont !== '' ? element.complimentCont : element.codValue2,
          reservedTime: '',
          customField: {
            dataType: 'contact',
            stampPath: makeFilePath(element.codValue),
          },
        }
        calendarDatas.push(calendarData)
      })
      // 面談イベント予約状況
      const eventList: InterviewEvent[] = result.data.eventList
      eventList.forEach(element => {
        const calendarData: CalendarData = {
          date: new DateUtil(element.sgdStartDatime).date,
          title: element.eventTitle,
          reservedTime: dateFormatter(element.sgdStartDatime, 'hhmm', '') + '〜',
          customField: {
            dataType: 'interview',
            stampPath: '',
          },
        }
        calendarDatas.push(calendarData)
      })
    } else {
      // TODO: エラー処理
    }
    return calendarDatas
  }
}
