import http from '~/http-common'
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { makeQueryStrings } from '~/libs/makeQueryStrings'
import type { ReservedEvent } from '~~/types/reservedEvent'
import type { ReservedEventRes } from '~~/types/reservedEventRes'
import { ApiPath } from '~/enum/apiPath'

export const RESERVED_EVENT_MODULE_NAME = 'ReservedEventModule'

@Module({
  name: RESERVED_EVENT_MODULE_NAME,
  namespaced: true,
  stateFactory: true,
})
export default class ReservedEventModule extends VuexModule {

  /**
   * 予約済みイベント一覧
   */
  private reservedEventList: ReservedEvent[] = []

  /**
   * 予約済みイベント一覧を取得
   */
  get getReservedEventList() {
    return this.reservedEventList
  }

  /**
   * イベントIDから予約済みイベントを取得
   */
  get getReservedEventByEventId() {
    return (eventId: number) => this.reservedEventList.find((e) => e.applyId = eventId)
  }

  @Mutation
  public resetReservedEvents() {
    this.reservedEventList = []
  }

  @Mutation
  public setReservedEvents(events: ReservedEvent[]) {
    this.reservedEventList = events
  }

  @Action
  public async getReservedEventDatas() {
    // APIから予約済みイベントの一覧を取得
    const path = ApiPath.RESERVED_EVENT_MODULE_GET_RESERVED_EVENT_DATAS
    const headers = {
      mode: 'cors',
      credentials: 'include',
      csrfToken: sessionStorage.getItem('token')!,
    }

    const eventsList:ReservedEvent[] = []

    // 未実施のイベント一覧を取得
    const queryStrings1: string = makeQueryStrings({flg: 1})
    const response1: ReservedEventRes = await http.get(path + queryStrings1, { headers: headers })
    if (response1.data.code == 0) {
      eventsList.push(...response1.data.stuList)
    }
    // 実施済みのイベント一覧を取得
    const queryStrings2: string = makeQueryStrings({flg: 2})
    const response2: ReservedEventRes = await http.get(path + queryStrings2, { headers: headers })
    if (response2.data.code == 0) {
      eventsList.push(...response2.data.stuList)
    }

    // storeに設定
    this.setReservedEvents(eventsList)
  }
}
